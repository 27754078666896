<script setup lang="ts">
import { vMaska } from 'maska';
import type { InputProps } from '@/interfaces';

const emit = defineEmits<{
  (e: 'focus'): void;
  (e: 'keyUp'): void;
  (e: 'keyUpEnter'): void;
  (e: 'keyDownTab'): void;
  (e: 'toggleDisabled'): void;
}>();

const vModel = defineModel<any>();
const { error, icon } = defineProps<InputProps>();

const statusClass = computed(() => ({ error, icon: !!icon }));
</script>

<template>
  <div class="input" :class="classMods">
    <lazy-common-input-title
      v-if="name"
      :name="name"
      :required="required"
    ></lazy-common-input-title>
    <div v-if="static" class="static">{{ vModel }}</div>
    <input
      v-else-if="!!mask"
      v-model="vModel"
      v-maska
      :data-maska="mask"
      :type="type"
      :placeholder="placeholder"
      :autofocus="!!autofocus"
      :class="statusClass"
      :disabled="disabled"
      @focus="emit('focus')"
      @keyup="emit('keyUp')"
      @keydown.tab="emit('keyDownTab')"
      @keyup.enter="emit('keyUpEnter')"
    />
    <textarea
      v-else-if="type === 'textarea'"
      v-model="vModel"
      type="textarea"
      :placeholder="placeholder"
      :autofocus="!!autofocus"
      :class="statusClass"
      :disabled="disabled"
      @focus="emit('focus')"
      @keyup="emit('keyUp')"
      @keydown.tab="emit('keyDownTab')"
      @keyup.enter="emit('keyUpEnter')"
    />
    <input
      v-else
      v-model="vModel"
      :type="type"
      :placeholder="placeholder"
      :autofocus="!!autofocus"
      :class="statusClass"
      :disabled="disabled"
      @focus="emit('focus')"
      @keyup="emit('keyUp')"
      @keydown.tab="emit('keyDownTab')"
      @keyup.enter="emit('keyUpEnter')"
    />
  </div>
</template>

<style lang="scss" scoped>
.input {
  @apply w-full;
  .static,
  input,
  textarea {
    @apply w-full py-2 px-3 border border-solid border-gray-4 rounded-md;
    &:disabled {
      @apply bg-gray-4 text-gray-2;
    }
  }
  textarea {
    @apply resize-none h-[100px];
  }
}
</style>
